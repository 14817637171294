<template>
<AuthLayout
  :breadcrumbs="breadcrumbs"
  :sidebar="true"
>
<template #title>
  
  <StepsLayout
    active="symptoms"
  />
  
</template>
<template #content>
  <div class="SymptomsPage">
    
    <SymptomsTable
      ref="SymptomsTable"
    />
  
  </div>
</template>
<template #sidebar>
  
  <SymptomsForm
    ref="SymptomsForm"
    @onSubmit="onSubmit"
    @onContinue="onContinue"
  />
  
</template>
</AuthLayout>
</template>

<script>
import AuthLayout from "@/components/cms/auth.layout/AuthLayout"
import StepsLayout from "@/components/cms/auth.layout/StepsLayout"
import SymptomsTable from "@/components/cms/symptoms/SymptomsTable"
import SymptomsForm from "@/components/cms/symptoms/SymptomsForm"
import { mapActions, mapGetters, mapMutations } from "vuex"
import { isSuccess } from '@/helpers/http.helper'
import { toast } from "@/helpers/error.helper"

export default {
  name: "SymptomsPage",
  components: {
    AuthLayout,
    StepsLayout,
    SymptomsForm,
    SymptomsTable,
  },
  data() {
    return {
      breadcrumbs: [
        { name: `Requests`, route: { name: `cms.requests` } },
        { name: this.$route.params.barcode },
      ],
    }
  },
  computed: {
    ...mapGetters({
      requestIsInit: 'CmsRequestStore/getIsInit',
      samplesInit: 'CmsSampleStore/getInit',
      requestInit: 'CmsRequestStore/getInit',
    }),
    barcode() {
      return this.$route.params.barcode
    },
  },
  methods: {
    ...mapMutations({
      setRequestInitField: 'CmsRequestStore/setItemInitField',
      setRequestField: 'CmsRequestStore/setItemField',
    }),
    ...mapActions({
      fetchSamples: 'CmsSampleStore/fetchInit',
      updateSamples: 'CmsSampleStore/updateById',
    }),
    async onSubmit() {
      return Promise.all([
        this.$refs.SymptomsTable.$refs.form.validate(),
        this.$refs.SymptomsForm.$refs.form.validate(),
      ]).then(async (responses) => {
        return this.updateSamples({ id: this.samplesInit.barcode, payload: this.samplesInit.toApi() })
          .then((response) => {
            if (response instanceof Error || !isSuccess(response.status)) throw response
            toast({ type: 'success', msg: 'Update successful' })
            this.setRequestInitField({ field: 'status', value: 'symptoms_added' })
            if (this.requestIsInit) {
              this.setRequestField({ id: this.requestInit.id, field: 'status', value: 'symptoms_added' })
            }
            return response
          })
      })
      .catch((error) => {
        return error
      })
    },
    async onContinue() {
      await this.onSubmit()
        .then((response) => {
          if (!(response instanceof Error || !isSuccess(response.status))) {
            this.$router.push({ name: 'cms.conclusion', params: { barcode: this.samplesInit.barcode } })
          }
        })
    },
  },
  async mounted() {
    if (this.samplesInit.barcode !== this.barcode) {
      await this.fetchSamples(this.barcode)
    }
  }
}
</script>

<style lang="scss" scoped>
.SymptomsPage {
  width: calc(100% - 320px - 24px); // scrollbar-width: thin fix
  background-color: white;
  padding: 24px;
}
</style>