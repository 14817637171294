<template>
  <div class="SymptomsTable">

    <a-form
        ref="form"
        :model="samplesInit"
    >
      <a-table
          :dataSource="samplesInit.samples"
          :columns="columns"
          :pagination="false"
          :scroll="{ y: `calc(100vh - ${sum([
        48,     // HeaderLayout
        104,    // SubHeaderLayout
        24, 24, // SymptomsPage
        55,     // ant-table-header
        24,     // AuthLayout__wrap
      ])}px)` }"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'number'">
            {{ `Sample ${record.number}` }}
          </template>
          <template v-if="column.key === 'time'">
            <a-form-item
                class="SymptomsTable__field"
                :name="['samples', record.number - 1, 'time']"
                :rules="[{ required: true, message: '' }]"
            >
              <a-input-number
                  :ref="`input_${column.number}_${record.number}`"
                  v-model:value="samplesInit['samples'][record.number - 1].time"
                  class="SymptomsTable__time"
                  :min="Math.max( ...samplesInit.samples.map(i => i.time).slice(0, record.number - 1) ) + 1"
                  :max="1000"
                  :disabled="record.number === 1"
                  :controls="false"
                  @pressEnter="pressEnter(column.number, record.number)"
              />
            </a-form-item>
          </template>
          <template v-if="column.key === 'bloating'">
            <a-form-item
                class="SymptomsTable__field"
                :name="['samples', record.number - 1, 'bloating']"
                :rules="[{ required: true, message: '' }]"
            >
              <a-input-number
                  :ref="`input_${column.number}_${record.number}`"
                  v-model:value="samplesInit['samples'][record.number - 1].bloating"
                  :min="0"
                  :max="10"
                  :controls="false"
                  @pressEnter="pressEnter(column.number, record.number)"
              />
            </a-form-item>
          </template>
          <template v-if="column.key === 'abdominal_pain'">
            <a-form-item
                class="SymptomsTable__field"
                :name="['samples', record.number - 1, 'abdominal_pain']"
                :rules="[{ required: true, message: '' }]"
            >
              <a-input-number
                  :ref="`input_${column.number}_${record.number}`"
                  v-model:value="samplesInit['samples'][record.number - 1].abdominal_pain"
                  :min="0"
                  :max="10"
                  :controls="false"
                  @pressEnter="pressEnter(column.number, record.number)"
              />
            </a-form-item>
          </template>
          <template v-if="column.key === 'nausea'">
            <a-form-item
                class="SymptomsTable__field"
                :name="['samples', record.number - 1, 'nausea']"
                :rules="[{ required: true, message: '' }]"
            >
              <a-input-number
                  :ref="`input_${column.number}_${record.number}`"
                  v-model:value="samplesInit['samples'][record.number - 1].nausea"
                  :min="0"
                  :max="10"
                  :controls="false"
                  @pressEnter="pressEnter(column.number, record.number)"
              />
            </a-form-item>
          </template>
        </template>
      </a-table>
    </a-form>

  </div>
</template>

<script>
import {mapGetters} from "vuex"
import _ from "lodash"

export default {
  name: "SymptomsTable",
  data() {
    return {
      samplesCache: null,
      columns: [
        {
          number: 1,
          key: 'number',
          dataIndex: 'number',
          title: 'Sample No',
        },
        {
          number: 2,
          key: 'time',
          dataIndex: 'time',
          title: 'Time (min)',
        },
        {
          number: 3,
          key: 'bloating',
          dataIndex: 'bloating',
          title: 'Bloating (0-10)',
        },
        {
          number: 4,
          key: 'abdominal_pain',
          dataIndex: 'abdominal_pain',
          title: 'Abdominal Pain (0-10)',
        },
        {
          number: 5,
          key: 'nausea',
          dataIndex: 'nausea',
          title: 'Nausea (0-10)',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      samplesInit: 'CmsSampleStore/getInit',
    }),
  },
  methods: {
    sum(v) {
      return _.sum(v)
    }, // lodash sum
    pressEnter(column_number, record_number) {
      let obj = this.$refs['input_' + column_number + '_' + (record_number + 1)],
          col = column_number,
          rec = record_number

      if (typeof obj == "undefined") {
        col = column_number + 1
        rec = 0
      }

      this.$refs['input_' + col + '_' + (rec + 1)]?.focus()
    },
  },
  mounted() {
    this.samplesCache = _.cloneDeep(this.samplesInit)
  }
}
</script>

<style lang="scss" scoped>
.SymptomsTable {

  &__time.ant-input-number-disabled {
    background-color: white;
  }

  &__field {
    margin-bottom: 0;

    &:deep(.ant-form-item-explain) {
      display: none;
    }

    &:deep(.ant-input-number) {
      width: 100%;
    }

    &:deep:not(.ant-form-item-has-error) .ant-input-number {
      border-color: $primary10;
    }
  }

  &:deep(.ant-table-body) {
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.15) transparent;

    &::-webkit-scrollbar {
      width: 13px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 5px 15px 15px 5px;
      background-color: rgba(0, 0, 0, 0.15);
      border-right: 8px transparent solid;
      background-clip: padding-box;
    }

    &::-webkit-scrollbar-track {
      margin: 10px;
    }
  }

}
</style>