<template>
<div class="SymptomsForm">
  
  <a-form
    ref="form"
    :model="samplesInit"
  >
    <a-form-item
      class="SymptomsForm__field"
      name="patient_comments"
      :rules="[{ max: 255, message: '' }]"
    >
      <span class="ResultLayout__label">
        Patient's comments
      </span>
      <a-textarea
        v-model:value="samplesInit.patient_comments"
        :rows="4"
      />
    </a-form-item>
    
    <div class="SymptomsForm__buttons">
      <a-button
        type="green-outline"
        class="SymptomsForm__button"
        @click="$emit('onSubmit')"
      >
        Save
      </a-button>
      <a-button
        type="green"
        class="SymptomsForm__button"
        @click="$emit('onContinue')"
      >
        Save & Continue
      </a-button>
    </div>
  </a-form>
  
</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"

export default {
  name: "SymptomsForm",
  computed: {
    ...mapGetters({
      samplesInit: 'CmsSampleStore/getInit',
    }),
  },
  methods: {
    ...mapActions({
      fetchSamples: 'CmsSampleStore/fetchInit',
    }),
  },
}
</script>

<style lang="scss" scoped>
.SymptomsForm {
  margin-top: 12px;
  
  &__buttons {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-top: 15px;
    gap: 15px;
  }
  
  &__button {
    flex: 1;
  }
  
  &__field {
    margin-bottom: 0;
    
    &:deep(.ant-form-item-explain) {
      display: none;
    }
  }
  
}

.ResultLayout {

  &__label {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: $secondary45;
  }

}
</style>