<template>
<div class="StepsLayout">
  
  <div class="StepsLayout__list">
    <div
      :class="{
        'StepsLayout__item': true,
        'StepsLayout__active': active === step,
        'StepsLayout__link': statusCurrent <= 2 && index <= statusCurrent,
      }"
      @click="statusCurrent <= 2 && index <= statusCurrent ? $router.push({ name: `cms.${step}`, params: { barcode } }) : null"
      v-for="(step, index) in steps"
    >
      {{ `${index + 1}. ${step}` }}
    </div>
  </div>
  
</div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "StepsLayout",
  props: {
    active: String,
  },
  data() {
    return {
      steps: [
        'symptoms',
        'conclusion',
        'report',
      ],
      statuses: [
        'new_request',
        'symptoms_added',
        'conclusion_added',
        'report_completed',
      ],
    }
  },
  computed: {
    ...mapGetters({
      requestInit: 'CmsRequestStore/getInit',
    }),
    statusCurrent() {
      return this.statuses.indexOf(this.requestInit.status)
    },
    barcode() {
      return this.$route.params.barcode
    }
  },
}
</script>

<style lang="scss" scoped>
.StepsLayout {
  &__list {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    gap: 32px;
  }
  
  &__item {
    padding-bottom: 7px;
    text-transform: capitalize;
  }
  
  &__item:not(&__active) {
    color: $primary10-04;
  }
  
  &__active {
    border-bottom: 3px solid $polarGreen7;
    color: $primary10;
  }
  
  &__link:not(&__active) {
    cursor: pointer;
    color: $primary10;
  }
}
</style>